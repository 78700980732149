import React from 'react';

import { Row, Col } from '/src/components/atoms/GridRow';
import YouTubeEmbed from '/src/components/atoms/YouTubeEmbed';
import Layout from '/src/components/layout';
import SEO from '/src/components/atoms/Seo';
import ContentSection from '/src/components/atoms/ContentSection';

const MediaPage = () => (
  <Layout hideHeader>
    <SEO title="Media" />
    <ContentSection banner parallax center bgSrc="/images/uploads/eoa_live.jpg">
      <h1 className="heading1">Media</h1>
    </ContentSection>
    <ContentSection center light>
      <h2 className="heading3">Eye on Attraction - Pleasantville</h2>
      <Row center vertGutter>
        <Col width={8}>
          <YouTubeEmbed
            title="Pleasantville Lyric Video"
            youtubeId="fEcvIrf1qEg"
          />
        </Col>
      </Row>
      <h2 className="heading3">Eye on Attraction - Glass Castles</h2>
      <Row center vertGutter>
        <Col width={8}>
          <YouTubeEmbed
            title="Glass Castles Lyric Video"
            youtubeId="vD4rRszAFbk"
          />
        </Col>
      </Row>
    </ContentSection>
    {/* <ContentSection center light>
      <h2>Photos</h2>
      <p>Adelitas Way Tour | Winter 2016</p>
    </ContentSection> */}
  </Layout>
);

export default MediaPage;
